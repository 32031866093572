import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ResetPassword from "./reset-password";
import VerifyEmail from "./verify-email";
import { NotificationContainer } from "react-notifications";
import createBrowserHistory from "history/createBrowserHistory";
import configAxios from "./services";

import logo from './logo.svg';
import Share from './share';
// import './App.css';
var history = createBrowserHistory();

class App extends Component {

  constructor() {
    super();
    console.log("called");
    configAxios(history);
  }

  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route path="/reset-password/:token" exact component={ResetPassword} />
            <Route path="/verify-email/:token" exact component={VerifyEmail} />
            <Route path="/share" exact component={Share} />
          </Switch>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
