import axios from "axios";
import {
    loadProgressBar
} from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import {
    NotificationManager
} from 'react-notifications';

loadProgressBar({
    showSpinner: false
});
const configAxios = (history) => {
    axios.defaults.baseURL = "https://api.yepwego.com/api";// "https://api.yepwego.continuousnet.com/api";
    axios.defaults.timeout = 10000;
    // axios.defaults.headers.common['Authorization'] = localStorage.getItem('adminToken') || '';
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.error("Une erreur est survenue");

        return Promise.reject(error);
    });
}

export default configAxios;
