import React, { Component } from "react";
import { FieldGroup, Button } from "./components";
import { Form, Card, CardBody, CardHeader } from "reactstrap";
import authService from "./services/auth";
import { NotificationManager } from "react-notifications";

import ipocrat from "./assets/imgs/logoImage.png";

export default class VerifyEmail extends Component {
  state = {

  };

  componentDidMount() {
    authService.verifyEmail(this.props.match.params.token).then(() => {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Email vérifié avec succès");
    });
  }

  render() {
    return (
      <div className="auth-bg">
      </div>
    )
  }

}