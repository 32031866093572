import React, { Component } from 'react';
import { FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

export class FieldGroup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            focus: false
        }
    }
    render(){
        const { label,addonLeft,addonRight, formGroupProps, labelProps, inputProps, inputGroupProps, inputGroupAddonProps, select, options, feedback, iconleft } = this.props;
        var classes = " ";
        if(inputGroupProps !== undefined){
            if(inputGroupProps.className !== undefined){
                classes+=inputGroupProps.className+" ";
            }
        }
        if(addonLeft !== undefined || addonRight !== undefined)
            return (
                <InputGroup {...inputGroupProps} className={classes+(this.state.focus?"input-group-focus":"")}>
                    {addonLeft !== undefined ? (<InputGroupAddon {...inputGroupAddonProps}><InputGroupText><i className={addonLeft}></i></InputGroupText></InputGroupAddon>):""}
                    <Input {...inputProps} onFocus={(e) => this.setState({focus:true})} onBlur={(e) => this.setState({focus:false})}/>
                    {addonRight !== undefined ? (<InputGroupAddon {...inputGroupAddonProps}><InputGroupText><i className={addonRight}></i></InputGroupText></InputGroupAddon>):""}
                </InputGroup>
            )
        return (
            !select ? (
            inputProps.type === "radio" || inputProps.type === "checkbox" ? (
                <FormGroup {...formGroupProps} className={inputProps.type === "radio" ? "form-check-radio":""}>
                    <Label {...labelProps}>
                        <Input {...inputProps} />
                        <span className="form-check-sign"></span>
                        <span style={{marginLeft: 30}}>{label ? label : ""}</span>
                    </Label>
                </FormGroup>):(
                <FormGroup {...formGroupProps} className={iconleft? "iconleft "+iconleft.icon : ""}>
                    {label ? <Label {...labelProps}>{label}</Label>:""}
                    <Input {...inputProps} />
                    <FormFeedback>{feedback}</FormFeedback>
                </FormGroup>)
            ): (
                <FormGroup {...formGroupProps}>
                {label ? <Label {...labelProps}>{label}</Label>:""}
                    <select {...inputProps} className="form-control">
                        {options && options.map((op, i) => <option key={i} value={op.value}>{op.label}</option>)}
                    </select>
                </FormGroup>
            )
        );
    }
}

export class FormInputs extends Component{
    render(){
        var row = [];
        for(var i = 0; i < this.props.ncols.length ; i++){
            row.push(
                <div key={i} className={this.props.ncols[i]}>
                    <FieldGroup
                        {...this.props.proprieties[i]}
                    />
                </div>
            );
        }
        return (
            <div className="row">
                {row}
            </div>
        );
    }
}

FormInputs.propTypes = {
    ncols: PropTypes.arrayOf(PropTypes.string),
    proprieties: PropTypes.arrayOf(PropTypes.object)
}

export default FormInputs;
