import React, { Component } from "react";
import { FieldGroup, Button } from "./components";
import { Form, Card, CardBody, CardHeader } from "reactstrap";
import authService from "./services/auth";
import { NotificationManager } from "react-notifications";

import ipocrat from "./assets/imgs/logoImage.png";

export default class ResetPassword extends Component {
  state = {
    password1: "",
    password2: ""
  };

  render() {
    return (
      <div className="auth-bg">
        <div className="container text-center">
          <div className="fullheight">
            <Card className="cardbox cardAuth borderRound" >
              <Form
                onSubmit={this.handleSubmit}
                noValidate
              >
                <CardHeader>
                  <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: 60, paddingBottom: 60 }}>
                    {/* <img src={ipocrat} alt='ipocrat' /> */}
                  </div>
                </CardHeader>
                <CardBody className="pb-0">
                  <div
                    className="text-center pb-3"
                    style={{
                      color: "grey"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontFamily: "HelveticaBold"
                      }}
                    >
                      Changer mot de passe
                    </span>
                  </div>
                  <FieldGroup
                    iconleft={{
                      icon: "lock-icon"
                    }}
                    inputProps={{
                      type: "password",
                      placeholder: "Nouveau mot de passe",
                      value: this.state.password1,
                      onChange: ev =>
                        this.setState({ password1: ev.target.value })
                    }}
                  />
                  <FieldGroup
                    iconleft={{
                      icon: "lock-icon"
                    }}
                    inputProps={{
                      type: "password",
                      placeholder: "Confirmer mot de passe",
                      value: this.state.password2,
                      invalid:
                        this.state.password2.length > 0 &&
                        this.state.password1 !== this.state.password2,
                      onChange: ev =>
                        this.setState({ password2: ev.target.value })
                    }}
                    feedback={"Mots de passe ne coincident pas"}
                  />
                  <div className="position-relative">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={
                        this.state.password1.length < 1 ||
                        this.state.password2.length < 1 ||
                        this.state.password1 !== this.state.password2
                      }
                      className="w-100 my-5 btn-lg"
                    >
                      Confirmer
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit = ev => {
    ev.preventDefault();

    console.log("token ===== ",this.props.match.params.token);

    authService
      .changePassword(this.props.match.params.token, this.state.password1)
      .then(() => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success("Mot de passe est réinitialiser avec succès");
      });
  };
}
