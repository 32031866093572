import React, { Component } from "react";

import { FieldGroup, Button } from "./components";
import { Form, Card, CardBody, CardHeader } from "reactstrap";
// import { Linking} from 'react'


export default class Share extends Component {

    componentDidMount() {

    }

    handleClick = ev => {
        const url = window.location.href;
        const id = url.split('?id=')[1];
        window.location.replace("yepwego://share?id=" + id);
    };

    render() {
        const shareBtn = {
            padding: '0.3em',
            paddingLeft: 0,
            // fontSize: '20px',
            color: 'white',
            backgroundColor: '#FF4C00'
          };
        return (
            <div className="auth-bg share-bg">
                <div className="container text-center">
                    <div className="fullheight">
                        <div className="position-relative">
                            <Button
                           style={shareBtn}
                            onClick={this.handleClick}
                            type="submit"
                            className="w-50 sm my-5 btn-sm mb-7 color-back"
                            >
                            Ouvrir l'activité dans l'application
                            </Button>
                        </div>
                        <div class="share-content">
                            <div class="container">
                                <div >
                                    <h2 className="mt-4">Rejoignez YepWeGo!</h2>
                                    <h5><em>Disponible Gratuitement </em></h5>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.coditup.yepwego&amp;hl=fr" target="_blank" rel="noopener">
                                        <img className=" mb-5" src="http://www.yepwego.com/wp-content/uploads/2019/01/play-store.png" /></a>

                                        <a href="https://apps.apple.com/us/app/yepwego/id1393141927" target="_blank" rel="noopener">
                                        <img className=" mb-5" src="http://www.yepwego.com/wp-content/uploads/2019/01/Appstore.png" /></a>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
