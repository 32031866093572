import axios from "axios";
// import commonService from "./common";

const authService = {

    changePassword: function (token, password) {
        return axios.post('/user/reset-password', {
            newPassword: password
        }, {
            headers: {
                'Authorization': token
            }
        })
    },verifyEmail: function (token) {
        
        console.log("token ==== ",token);

        return axios.post('/user/setEmailVerified',{},{
            headers: {
                'Authorization': token
            }
        })
    }

}

export default authService;